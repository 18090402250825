/**
 * 获取公告须知模拟数据
 */
export function getAnnouncementTheGuidelinesList(companyName, tencentName, contractName) {
    return [{
        num: "1",
        content: "集群注册地址（含企业新设立、招商部门批文项目迁址和地址续签）为完全<b>自动发放</b>，我司作为集群注册地址的托管单位,未委托任何中介机构代发放集群注册地址！<br /><b>本集群注册地址的受理解释权归" + companyName + "所有！</b> "
    }, {
        num: "2",
        content: "1个集群地址编号只能设立1家企业！企业领取新的营业执照后，务必在首份合同有效期内及时与我司签订《" + contractName + "》，防止企业被主管部门列入异常！<br />集群注册企业应当<b>每个季度</b>定期在集群注册系统上打卡更新企业的基本信息！<br />申请流程和企业打卡操作请关注“ " + tencentName + "” 公众号， 回复关键字“ 集群注册”、“ 打卡”， 获取登录网址和详细的操作指引。 "
    }, {
        num: "3",
        content: "下列情况<b><u>不能使用</u></b>我司的企业集群注册地址：<br />一、凡经营范围涉及《安徽省工商登记前置审批事项目录》中所列事项的企业；<br />二、从事生产、加工、制造、施工、可食用产品零售、种植、养殖、餐饮服务、居民服务业、旅业、住宿业、金融业、娱乐服务、网吧、药品、医疗器械、易燃易爆品、有毒危险品、放射性物品、运输、证券服务、拍卖、典当、担保、小额贷款等行业；<br />三、“厂、店、部、院、中心、工作室、事务所、商行”等个人独资企业。"
    }, {
        num: "4",
        content: "根据相关规定，安徽省税务局需对办理出口退税的企业进行现场核查企业在安徽省内的登记场所，且必须为企业实际办公场所，该类企业无法使用本集中注册管理地址！"
    }, {
        num: "5",
        content: "<b>企业所需上传资料： </b> <br />一、新设立企业为股东之一的证件（法人股东请上传营业执照和法定代表人身份证）、续签企业为营业执照彩色扫描件；<br />二、申请集群地址承租人和法定代表人身份证正反双面彩色扫描件；<br /><b>请将上传资料清晰彩色扫描成PDF文件，依次对应上传在地址申请的对应附件处！</b><br />"
    }, {
        num: "6",
        content: "<b>集群注册地址申请流程为： </b>登录系统--填写资料--提交申请--等待审核--审核通过--线上认证--集群地址承租方登录系统--获取合同（ 下载PDF）。 <br /><b>办理时间为1-2个工作日（不包括提交申请当天）！</b><br /> 集群注册【线上认证】环节面向持有中华人民共和国身份证的公民，或者法人机构的法定代表人为持有中华人民共和国身份证的公民。港澳台及外籍人士申请集群地址，请关注“" + tencentName + "”公众号，输入“外资”获取指引。"
    }, {
        num: "7",
        content: "<b>请保持企业法人电话及企业联系人电话畅通，我们会对您填写的信息进行回访核实，感谢您的配合！</b>"
    }]
}

/**
 * 获取选择地址时的提示信息
 */
export function getTip(flag) {
    let tip = ""
    switch (flag) {
        case 1:
            // 新设立申请
            // tip = "集群注册地址申请通过的首份合同《房屋租赁合同》有效期为<b style='font-size:18px'>3个月</b style='font-size:18px'>，企业领取新的营业执照后，务必在首份合同有效期内及时与我司签订《商务秘书服务合同》，<b style='font-size:18px'>防止企业被主管部门列入异常</b style='font-size:18px'>"
            break;
        case 2:
            // 迁入
            // tip = "集群注册地址申请通过的首份合同《房屋租赁合同》有效期为<b style='font-size:18px'>3个月</b style='font-size:18px'>，企业领取新的营业执照后，务必在首份合同有效期内及时与我司签订《商务秘书服务合同》，<b style='font-size:18px'>防止企业被主管部门列入异常</b style='font-size:18px'>"
            break;
        case 3:
            // 续签
            // tip = "集群注册地址申请通过的首份合同《房屋租赁合同》有效期为<b style='font-size:18px'>3个月</b style='font-size:18px'>，企业领取新的营业执照后，务必在首份合同有效期内及时与我司签订《商务秘书服务合同》，<b style='font-size:18px'>防止企业被主管部门列入异常</b style='font-size:18px'>"
            break;

        default:
            break;
    }
    return tip
}

/**
 * 获取模拟区域数据
 */
export function getAreaList() {
    return [{
        id: "1",
        name: "蜀山区"
    }, {
        id: "2",
        name: "瑶海区"
    }, {
        id: "3",
        name: "庐阳区"
    }, {
        id: "4",
        name: "高新区"
    }, {
        id: "5",
        name: "新站区"
    }, {
        id: "6",
        name: "包河区"
    }, {
        id: "7",
        name: "经开区"
    }, {
        id: "8",
        name: "庐江县"
    }, {
        id: "9",
        name: "肥东县"
    }, {
        id: "10",
        name: "肥西县"
    }, {
        id: "11",
        name: "长丰县"
    }, ]
}

/**
 * 拟定公司类型
 */
export function companyTypeList() {
    return [{
        label: "内资",
        options: [{
            label: "有限责任公司",
            value: "有限责任公司",
            type: "0"
        }, {
            label: "股份责任公司",
            value: "股份责任公司",
            type: "0"
        }, {
            label: "个人独资企业",
            value: "个人独资企业",
            type: "0"
        }, {
            label: "个体工商户",
            value: "个体工商户",
            type: "1"
        }, {
            label: "*分公司(分支机构",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "普通合伙企业",
            value: "普通合伙企业",
            type: "0"
        }, {
            label: "有限合伙企业",
            value: "有限合伙企业",
            type: "0"
        }, {
            label: "特殊普通合伙企业",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "*分公司(分支机构",
            value: "特殊普通合伙企业",
            type: "0"
        }, ]
    }, {
        label: "外资",
        options: [{
            label: "有限责任公司",
            value: "有限责任公司",
            type: "0"
        }, {
            label: "股份责任公司",
            value: "股份责任公司",
            type: "0"
        }, {
            label: "普通合伙企业",
            value: "普通合伙企业",
            type: "0"
        }, {
            label: "有限合伙企业",
            value: "有限合伙企业",
            type: "0"
        }, {
            label: "特殊普通合伙企业",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "*分公司(分支机构)",
            value: "*分公司(分支机构)",
            type: "0"
        }, {
            label: "有限合伙企业(台、港、澳资)",
            value: "有限合伙企业(台、港、澳资)",
            type: "0"
        }, ]
    }]
}

/**
 * 拟定公司类型 个体户
 */
export function companyTypeListByPerson() {
    return [{
        label: "内资",
        options: [{
            label: "个体工商户",
            value: "个体工商户",
            type: "1"
        }, ]
    }]
}

/**
 * 拟定公司类型 企业
 */
export function companyTypeListByCompany() {
    return [{
        label: "内资",
        options: [{
            label: "有限责任公司",
            value: "有限责任公司",
            type: "0"
        }, {
            label: "股份责任公司",
            value: "股份责任公司",
            type: "0"
        }, {
            label: "个人独资企业",
            value: "个人独资企业",
            type: "0"
        }, {
            label: "*分公司(分支机构",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "普通合伙企业",
            value: "普通合伙企业",
            type: "0"
        }, {
            label: "有限合伙企业",
            value: "有限合伙企业",
            type: "0"
        }, {
            label: "特殊普通合伙企业",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "*分公司(分支机构",
            value: "特殊普通合伙企业",
            type: "0"
        }, ]
    }, {
        label: "外资",
        options: [{
            label: "有限责任公司",
            value: "有限责任公司",
            type: "0"
        }, {
            label: "股份责任公司",
            value: "股份责任公司",
            type: "0"
        }, {
            label: "普通合伙企业",
            value: "普通合伙企业",
            type: "0"
        }, {
            label: "有限合伙企业",
            value: "有限合伙企业",
            type: "0"
        }, {
            label: "特殊普通合伙企业",
            value: "特殊普通合伙企业",
            type: "0"
        }, {
            label: "*分公司(分支机构)",
            value: "*分公司(分支机构)",
            type: "0"
        }, {
            label: "有限合伙企业(台、港、澳资)",
            value: "有限合伙企业(台、港、澳资)",
            type: "0"
        }, ]
    }]
}

/**
 * 获取业务申办信息格式数据
 */
export function getOrderData() {
    return [{
        title: "企业基本信息",
        tip: "",
        data: [{
            title: "拟定注册资本(万元):",
            tip: true, // 是否不得为空
            type: "pedometer", // 计步器
            value: "0.0",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "", // 提示文字
            max: 1000000,
            step: 0.1,
            key: "registeredCapital",
        }, {
            title: "拟定企业名称:",
            tip: true, // 是否不得为空
            type: "input", // 计步器
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "请输入企业名称", // 提示文字
            key: "companyName",
        }, {
            title: "拟定公司类型:",
            tip: true, // 是否不得为空
            type: "select-group", // 分组下拉框
            value: "有限责任公司",
            options: companyTypeList(),
            landscape: false, // 是否横屏显示
            placeholder: "请选择公司类型", // 提示文字
            key: "companyType",
        }, {
            title: "拟定行业类型:",
            tip: true, // 是否不得为空
            type: "input-search", // 输入框
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "请选择行业类型", // 提示文字
            key: "industryType",
        }, {
            title: "拟定经营范围:",
            tip: true, // 是否不得为空
            type: "textarea-but", // 按钮-文本域
            value: "",
            truevalue: [],
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请选择经营范围", // 提示文字
            key: "scopeBusiness",
        }]
    }, {
        title: "集群注册地址承租方信息:",
        tip: "(注:承租方必须为拟注册成立企业的股东之一,须与名称核准的股东一致)",
        data: [{
                title: "承租方类型",
                tip: false, // 是否不得为空
                type: "radio_click", // 单选框
                value: "自然人",
                options: [{ label: "自然人", value: "自然人" }, { label: "法人", value: "法人" }],
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "lesseeType",
            }, {
                title: "公司名称:",
                tip: true, // 是否不得为空
                type: "input_show",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                show: false,
                placeholder: "请输入公司名称", // 提示文字
                key: "legalPersonCompany",
            }, {
                title: "营业执照编号:",
                tip: true, // 是否不得为空
                type: "input_show",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                show: false,
                placeholder: "请输入营业执照编号", // 提示文字
                key: "legalPersonBusinessLicense",
            }, {
                title: "姓名:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入姓名", // 提示文字
                key: "lesseeName",
            }, {
                title: "地区:",
                tip: false, // 是否不得为空 false可为空
                type: "radio", // 输入框
                value: "境内(中国大陆)",
                options: [{ label: "境内(中国大陆)", value: "境内(中国大陆)" }, { label: "境外(港、澳、台、外籍)", value: "境外(港、澳、台、外籍)" }],
                landscape: false, // 是否横屏显示
                placeholder: "请选择行业类型", // 提示文字
                key: "lesseeDistrict",
            }, {
                title: "证件类型:",
                tip: false, // 是否不得为空
                type: "input-disable", // 禁用输入框
                value: "身份证",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "lesseeDocumentType",
            }, {
                title: "证件号码:",
                tip: true, // 是否不得为空
                type: "input", // 禁用输入框
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入证件号码", // 提示文字
                key: "lesseeIdNumber",
            }, {
                title: "手机号:",
                tip: true, // 是否不得为空
                type: "input-phone", // 手机号+按钮
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入手机号", // 提示文字
                key: "lesseePhone",
                text: "发送验证码",
                isDisable: false, // 发送验证码的按钮禁用状态
                timer: null, // 发送验证码计时器
                countdown: 60, // 倒计时时间
            },
            {
                title: "验证码:",
                tip: true, // 是否不得为空
                type: "input", // 禁用输入框
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入验证码", // 提示文字
                key: "lesseeVerificationCode",
            }, {
                title: "承租方身份证明(正反面):",
                tip: true, // 是否不得为空
                type: "file_idCard", // 禁用输入框
                value: ["", ""],
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "选择附件", // 提示文字
                key: "lesseeFile",
                data: {
                    systemName: "cluster_register",
                    path: "",
                    title: "承租方身份证明附件"
                },
                text: ["", ""], // 文件名称  显示在界面上的
            },
            
        ]
    }, {
        title: "拟定法定代表人信息:",
        tip: "(注:请填写法定代表人实名认证手机号,校验失败5次,当日将锁定该手机号,无法办理业务)",
        data: [{
                title: "姓名",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "请输入姓名", // 提示文字
                key: "legalPersonName",
            }, {
                title: "证件类型:",
                tip: true, // 是否不得为空
                type: "select",
                value: "身份证",
                options: [{ label: "身份证", value: "身份证" },
                    { label: "护照", value: "护照" },
                    { label: "港澳通行证", value: "港澳通行证" },
                    { label: "台胞证", value: "台胞证" },
                    { label: "其他", value: "其他" },
                ],
                landscape: false, // 是否横屏显示
                placeholder: "请输入姓名", // 提示文字
                key: "legalPersonDocumentType",
            }, {
                title: "证件号码:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入证件号码", // 提示文字
                key: "legalPersonIdNumber",
            }, {
                title: "手机号码:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入手机号码", // 提示文字
                key: "legalPersonPhone",
                text: "发送验证码",
                isDisable: false, // 发送验证码的按钮禁用状态
                timer: null, // 发送验证码计时器
                countdown: 60, // 倒计时时间
            },
            // {
            // title: "验证码:",
            // tip: true, // 是否不得为空
            // type: "input",
            // value: "",
            // options: [],
            // landscape: false, // 是否横屏显示
            // placeholder: "请输入验证码", // 提示文字
            // key: "legalPersonVerificationCode",
            // },
            {
                title: "邮箱:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "请输入邮箱", // 提示文字
                key: "legalPersonEmail",
            }, {
                title: "身份证明(正反面)",
                tip: false, // 是否不得为空
                type: "file_idCard",
                value: ["", ""],
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "选择附件", // 提示文字
                key: "legalPersonFile",
                data: {
                    systemName: "cluster_register",
                    path: "",
                    title: "身份证明附件"
                },
                isDisable: false,
                text: ["",""], // 文件名称  显示在界面上的
            },
           
        ]
    }, {
        title: "备用联系人信息:",
        tip: "(请填写其他股东或法人家属)",
        data: [{
                title: "姓名:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入姓名", // 提示文字
                key: "spareContactsName",
            }, {
                title: "手机号:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入手机号", // 提示文字
                key: "spareContactsPhone",
                text: "发送验证码",
                isDisable: false, // 发送验证码的按钮禁用状态
                timer: null, // 发送验证码计时器
                countdown: 60, // 倒计时时间
            },
            // {
            // title: "验证码:",
            // tip: true, // 是否不得为空
            // type: "input",
            // value: "555",
            // options: [],
            // landscape: false, // 是否横屏显示
            // placeholder: "请输入验证码", // 提示文字
            // key: "spareContactsVerificationCode",
            // },
        ]
    }, {
        title: "实际经营场地",
        tip: "",
        data: [
            //     {
            // title: "证件类型:",
            // tip: true, // 是否不得为空
            // type: "select",
            // value: "房屋产权证明",
            // options: [{ label: "房屋产权证明", value: "房屋产权证明" }, { label: "出租屋备案证明", value: "出租屋备案证明" }, ],
            // landscape: true, // 是否横屏显示
            // placeholder: "请选择证件类型", // 提示文字
            // key: "siteType",
            //     },
            {
                title: "实际经营地址:",
                tip: true, // 是否不得为空
                type: "input",
                value: "",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "请输入房产证的房屋坐落地址", // 提示文字
                key: "siteAddress",
            },
            //     {
            // title: "经营场地证明:",
            // tip: true, // 是否不得为空
            // type: "file_reuse",
            // value: "",
            // options: [],
            // landscape: true, // 是否横屏显示
            // placeholder: "选择附件", // 提示文字
            // key: "siteFile",
            // data: {
            //     systemName: "cluster_register",
            //     path: "",
            //     title: "经营场地证明"
            // },
            // isDisable: false,
            // text: "",
            //     },
        ]
    }]
}

/**
 * 格式化提交数据
 * @param {*} array 
 * @param {*} this_ 
 */
export function formatSubmitData(array, this_) {
    let data = {}
    array.forEach(element => {
        element.data.forEach(ele => {
            if (ele.type != "tip" && ele.type != 'text-tip') {
                if (ele.key == "scopeBusiness") {
                    this_.$set(data, ele.key, JSON.stringify(ele.truevalue))
                } else if(ele.key == "legalPersonFile" || ele.key == 'lesseeFile' || ele.key == 'idCardFile') {
                    this_.$set(data, ele.key, JSON.stringify(ele.value))
                } else {
                    this_.$set(data, ele.key, ele.value)
                }
            }
        });
    });
    return data
}

/**
 * 提交前校验数据
 * @param {*} array 
 */
export function checkToSubmitData(array, this_) {
    let info = {
        legalPersonName: '',
        legalPersonPhone: '',
        spareContactsName: '',
        spareContactsPhone: '',
    }
    let data = {
        flag: true,
        mes: ""
    }
    array.forEach(element => {
        element.data.forEach(ele => {
            if (ele.key == 'legalPersonName' || ele.key == 'legalPersonPhone' || ele.key == 'spareContactsName' || ele.key == 'spareContactsPhone'  ) {
                info[ele.key] = ele.value
            }
            if (ele.key == 'registeredCapital' && (ele.value == "" || ele.value == "0.0" || ele.value == 0.0)) {
                data.flag = false
                data.mes = "拟定注册资本不能为0元!"
            } else if (ele.type == "input_show") {
                if (ele.show && (ele.value == "" || ele.value == [])) {
                    data.flag = false
                    data.mes = "请将界面信息补充完整!"
                }
            } else if (ele.tip && (ele.value == "" || ele.value == [])) {
                data.flag = false
                data.mes = "请将界面信息补充完整!"
            } else if (ele.type == 'input-phone' && !this_.$mobilePhoneNumberCheck(ele.value)) {
                data.flag = false
                data.mes = "手机号格式不正确!"
            }  
        });
    });
    if((info.legalPersonName == info.spareContactsName) ||  (info.legalPersonPhone == info.spareContactsPhone)) {
        data.flag = false
        data.mes = "法人信息和备用联系人不能相同!"
    }
    return data
}

/**
 * 提交前校验数据
 * @param {*} array 
 */
export function checkToSubmitData2(array, this_) {
    let data = {
        flag: true,
        mes: ""
    }
    array.forEach(element => {
        element.data.forEach(ele => {
           
            if (ele.type == "input_show") {
                if (ele.show && (ele.value == "" || ele.value == [])) {
                    data.flag = false
                    data.mes = "请将界面信息补充完整!"
                }
            } else if (ele.tip && (ele.value == "" || ele.value == [])) {
                data.flag = false
                data.mes = "请将界面信息补充完整!"
            } else if (ele.type == 'input-phone' && !this_.$mobilePhoneNumberCheck(ele.value)) {
                data.flag = false
                data.mes = "手机号格式不正确!"
            }  
        });
    });
    return data
}

/**
 * 将从数据库中拿到的数据匹配到界面上
 * @param {*} array // 数据格式
 * @param {*} data  // 数据库拿到的数据
 */
export function matchOrderData(array, data) {
    array.forEach(element => {
        element.data.forEach(ele => {
            if (ele.type == "file_reuse" && data[ele.key] != "") {
                ele.isDisable = true
                let file = data[ele.key]
                let d1 = file.split("\\")
                    // let d1 = file.split("/")
                if (d1.length > 0) {
                    let d2 = d1[d1.length - 1]
                    let d3 = d2.split("_")[1]
                    ele.text = d3 + ".pdf"
                }
            }
            if(ele.type == "file_idCard"  && data[ele.key] != "") {
                let fileList = JSON.parse(data[ele.key])
                console.log('fileList', fileList)
                fileList.forEach((e, i) => {
                    ele.value[i] = e
                    let file = e
                    let d1 = file.split("\\")
                    console.log('d1', d1)
                    if (d1.length > 0) {
                        let d2 = d1[d1.length - 1]
                        let d3 = d2.split("_")[1]
                        if(d3 ) {
                            ele.text[i] = '附件' + ++i
                        } 
                    } 
                })
            }
          
            if (ele.key == "scopeBusiness") {
                // 经营范围
                ele.truevalue = data[ele.key]
                ele.vlaue = ""
                data[ele.key].forEach((e, index) => {
                    ele.value = index == 0 ? e.name : ele.value + "," + e.name
                });
            } else if (ele.type != "text-tip" && ele.type != 'tip'&& ele.type != 'file_idCard') {
                ele.value = data[ele.key]
            }
        });
    });
    return array
}

export function editOrderData() {
    return [
        {
            title: "集群注册地址承租方信息:",
            tip: "",
            data: [
                {
                    title: "承租方身份证明(正反面):",
                    tip: true, // 是否不得为空
                    type: "file_idCard", // 禁用输入框
                    value: ["", ""],
                    options: [],
                    landscape: false, // 是否横屏显示
                    placeholder: "选择附件", // 提示文字
                    key: "lesseeFile",
                    data: {
                        systemName: "cluster_register",
                        path: "",
                        title: "承租方身份证明附件"
                    },
                    text: ["", ""], // 文件名称  显示在界面上的
                },
            ]
        },
        {
            title: "拟定法定代表人信息:",
            tip: "",
            data: [
                {
                    title: "身份证明(正反面)",
                    tip: false, // 是否不得为空
                    type: "file_idCard",
                    value: ["", ""],
                    options: [],
                    landscape: false, // 是否横屏显示
                    placeholder: "选择附件", // 提示文字
                    key: "legalPersonFile",
                    data: {
                        systemName: "cluster_register",
                        path: "",
                        title: "身份证明附件"
                    },
                    isDisable: false,
                    text: ["",""], // 文件名称  显示在界面上的
                },
            ]
        },
        {
            title: "实际经营场地",
            tip: "",
            data: [
                {
                    title: "实际经营地址:",
                    tip: true, // 是否不得为空
                    type: "input",
                    value: "",
                    options: [],
                    landscape: true, // 是否横屏显示
                    placeholder: "请输入房产证的房屋坐落地址", // 提示文字
                    key: "siteAddress",
                },
            ]
        }
    ]
}

/**
 * 根据状态返回对应文字
 */
export function getStatusName(status) {
    let name = "";
    switch (status) {
        case "0":
        case 0:
            // 待提交
            name = "待提交";
            break;
        case "1":
        case 1:
            // 待审核
            name = "待审核";
            break;
        case "2":
        case 2:
            // 待签名
            name = "待签名";
            break;
        case "3":
        case 3:
            // 已退回
            name = "已退回";
            break;
        case "4":
        case 4:
            // 已完成
            name = "已完成";
            break;
        case "5":
        case 5:
            // 已作废
            name = "已作废";
            break;
        case "6":
        case 6:
            // 已续签
            name = "已续签";
            break;
        default:
            break;
    }
    return name;
}

/**
 * 获取订单列表时
 * 根据状态返回对应按钮
 */
export function getStatusByData(butList, status) {
    switch (status) {
        case "0":
        case 0:
            // 待提交
            butList[1].statusShow = true;
            butList[2].statusShow = true;
            butList[3].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "1":
        case 1:
            // 待审核
            butList[2].statusShow = true;
            butList[4].statusShow = true;
            butList[9].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "2":
        case 2:
            // 待签名
            butList[2].statusShow = true;
            butList[0].statusShow = true;
            butList[7].statusShow = true;
            butList[8].statusShow = true;
            butList[10].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "3":
        case 3:
            // 已退回
            butList[1].statusShow = true;
            butList[2].statusShow = true;
            butList[3].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "4":
        case 4:
            // 已完成
            butList[2].statusShow = true;
            butList[5].statusShow = true;
            butList[6].statusShow = true;
            butList[7].statusShow = true;
            butList[8].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "5":
        case 5:
            // 已作废
            butList[2].statusShow = true;
            butList[5].statusShow = true;
            butList[6].statusShow = true;
            butList[8].statusShow = true;
            butList[11].statusShow = true;
            break;
        case "6":
        case 6:
            // 已续签
            butList[2].statusShow = true;
            butList[7].statusShow = true;
            butList[11].statusShow = true;
            break;
        default:
            break;
    }
    return butList;
}

/**
 * 订单分配的dialog数据
 */
// export function getAssignDialogData() {
//     return [{
//         title: "订单分配",
//         tip: "",
//         data: [{
//             title: "区域:",
//             tip: true, // 是否不得为空
//             type: "input-disable", // 仅文字展示
//             value: "",
//             options: [],
//             landscape: true, // 是否横屏显示
//             placeholder: "请输入代理姓名", // 提示文字
//             key: {},
//         }, ]
//     }, {
//         title: "跨区域提醒",
//         tip: "",
//         data: [{
//             title: "",
//             tip: true, // 是否不得为空
//             type: "tip", // 仅文字展示
//             value: "若当前订单为跨区域订单,系统将自动依据设置的扣费规则进行自动扣除费用,请保证账户余额充足!",
//             options: [],
//             landscape: true, // 是否横屏显示
//             placeholder: "请输入代理姓名", // 提示文字
//             key: {},
//         }, ]
//     }, ]
// }