import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as echarts from 'echarts';
import 'echarts-gl';

import Highcharts from 'highcharts'
import Highcharts3d from 'highcharts/highcharts-3d'
Highcharts3d(Highcharts)

import "./assets/font/font.css"
import "./assets/fonts/iconfont.css"

import "./assets/css/base.css"
Vue.config.productionTip = false

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});

import request from "./utils/request.js"
import mobilePhoneNumberCheck from "./utils/mobilePhoneNumberCheck.js"
import mailCheck from "./utils/mailCheck.js"
import idCardValidity from "./utils/idCardValidity.js"
import formatTime from "./utils/formatTime.js"
import numberReg from "./utils/numberReg.js"

Vue.prototype.$request = request
Vue.prototype.$mobilePhoneNumberCheck = mobilePhoneNumberCheck // 手机号校验
Vue.prototype.$mailCheck = mailCheck // 邮箱校验
Vue.prototype.$idCardValidity = idCardValidity // 身份证号校验
Vue.prototype.$formatTime = formatTime
Vue.prototype.$numberReg = numberReg
Vue.prototype.$echarts = echarts
Vue.prototype.$Highcharts = Highcharts

Vue.prototype.$copyright = ""
    // Vue.prototype.$copyright = "Copyright © 安徽弛振科技有限公司"

// Vue.prototype.$baseurl = 'http://192.168.0.111:8722' // 测试地址 李玉
// Vue.prototype.$baseurl = 'http://192.168.0.117:8722' // 测试地址 胡
// Vue.prototype.$baseurl = 'http://192.168.0.126:8722' // 测试地址 朱 47.98.44.199:8723
// Vue.prototype.$baseurl = 'http://192.168.0.112:8722' // 测试地址 鲍
// Vue.prototype.$baseurl = 'http://localhost:8722' // 测试地址 本地
// Vue.prototype.$baseurl = 'http://114.55.138.13:8722' // 正式地址
Vue.prototype.$baseurl = 'https://amggdqf.com' // 正式地址
// Vue.prototype.$baseurl = 'https://soft-ats.com' // 正式地址

// 创建事件总线   就相当于创建了一个新的vue实例
const bus = new Vue()
// 把bus挂载到了Vue的原型上, 保证所有的组件都能通过 this.$bus访问到事件总线
Vue.prototype.$bus = bus

import * as baseData from "./assets/js/baseData.js"

Vue.prototype.$baseData = baseData

import informationInput from "./components/information-input.vue"
Vue.component("information_input", informationInput)

import informationText from "./components/information-text.vue"
Vue.component("information_text", informationText)


import {
    sm4KeyGenerator,
    getRequestKey,
    encryptRequestData,
    getResponseJson
} from './utils/sm.js'


// sm4秘钥
const sm4Key = sm4KeyGenerator()


// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
    config => {
        if (window.sessionStorage.getItem('token')) {
            config.headers.token = window.sessionStorage.getItem('token')
        }
        // 请求数据加密
        // const requestKey = getRequestKey(sm4Key, false)
        // console.log(config);
        // console.log("RequestKey", requestKey);
        // config.headers.RequestKey = requestKey // 请求key
        //     // get请求映射params参数
        // config.data = encryptRequestData(config.data, sm4Key, config.method)

        return config;
    },
    error => {
        return Promise.reject(error);
    });

axios.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200
            // 返回值解密
            // if (res.config.method === 'post' && code === 200) {
            //     res.data = getResponseJson(res.data, myKey)
            // }
        return res
    },
    error => {
        console.log('err' + error)
        return Promise.reject(error)
    })


/**
 * 每次打包前改变package.json中的version
 */
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
    localStorage.clear()
    window.localStorage.setItem("appVersion", VUE_APP_VERSION);
    location.reload()
}


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')