<template>
  <div
    id="syssetting-product"
    class="div-flex-column-center"
    v-loading="loading"
  >
    <!-- 产品包 -->
    <div class="search-line">
      <el-input
        v-model="searchData.title"
        placeholder="标题"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.areaName"
        filterable
        clearable
        placeholder="请选择区域"
        @change="resetPage"
      >
        <el-option-group
          v-for="group in searchData.alreadyAreaSelectOption"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <el-date-picker
        v-model="searchData.validity"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="有效期"
        end-placeholder="有效期"
        @change="resetPage"
      >
      </el-date-picker>
      <div style="flex: 1"></div>
      <el-button
        style="margin-right: 10px"
        @click="handleClick({}, 'add')"
        type="primary"
        plain
        size="small"
        icon="el-icon-circle-plus-outline"
        v-if="addFlag"
        >添加</el-button
      >
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
          :fixed="item.fixed"
        >
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-for="(item, index) in tableOper"
              :key="index"
              v-show="item.show && item.statusShow && item.active !== 'add'"
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'edit' || dialogData.type == 'add'"
          style="width: 100%"
        >
          <div class="edit">
            <information_input
              :informationInputData="dialogData.data"
              @selectGroupChange="selectGroupChange"
            >
            </information_input>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'record'"
          style="width: 100%"
        >
          <div class="record-content">
            <el-table
              :data="dialogData.data"
              border
              style="width: 100%; height: 480px"
            >
              <el-table-column
                prop="companyName"
                label="赠送合作商名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="userName"
                label="赠送合作商代理用户"
                width="160"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="count"
                label="赠送数量"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="used"
                label="已使用数量"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="gmtCreate"
                label="赠送时间"
                width="160"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="operatorName"
                label="操作人"
                width="100"
              ></el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleClick(tempData, 'record')"
              :current-page.sync="dialogPageData.currentPage"
              :page-size="dialogPageData.pageSize"
              layout="total, prev, pager, next"
              :total="dialogPageData.total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as settingData from "../../assets/js/setting";
export default {
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        title: "",
        areaName: "",
        validity: [],
        companyName: "",
        alreadyAreaSelectOption: [],
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          title: "标题",
          key: "title",
          width: "180",
        },
        {
          title: "描述",
          key: "description",
          width: "180",
        },
        {
          title: "有效期",
          key: "validity",
          width: "180",
        },
        {
          title: "区域",
          key: "areaName",
          width: "120",
        },
        {
          title: "地址标签",
          key: "addressTag",
          width: "120",
        },
        {
          title: "总数量",
          key: "total",
          width: "100",
        },
        {
          title: "已使用数量",
          key: "giftCount",
          width: "100",
        },
        {
          title: "价格",
          key: "price",
          width: "100",
        },
        {
          title: "创建用户",
          key: "createUserName",
          width: "150",
          fixed: "right",
        },
      ],
      tableOper: [
        {
          name: "添加",
          key: "新增",
          active: "add",
          show: true, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "赠送",
          key: "赠送",
          active: "give",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "赠送记录",
          key: "赠送记录",
          active: "record",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: [],
      },
      dialogPageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      orderList: settingData.getOrderListByProduct(),
      tempData: {},
      roleId: "",
      userList: [],
      addFlag: false,
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.roleId = data[0].roleId;
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "系统设置",
          "产品包"
        );
        this.tableOper.forEach((item) => {
          if (item.key == "新增" && item.show && item.statusShow) {
            this.addFlag = true;
          }
        });
        this.getAreaSelectOption();
      });
    },
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          this.searchData.alreadyAreaSelectOption = data;
          this.getAllPartner();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getAllPartner() {
      this.$request(
        "get",
        "/agent/queryAllOptions",
        {},
        (data) => {
          this.userList = data.map((item) => {
            return {
              label: item.companyName,
              value: item.id,
            };
          });
          this.initData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getUser() {
      let requestData = {
        roleId: "5", // 1-总公司 2-区域审核 3-区域审核员工 4-普通用户 5-合作商 6-总公司员工
        status: "0", // 0-正常；1-停用
      };
      this.$request(
        "post",
        "/user/getUserOption",
        requestData,
        (data) => {
          this.userList = data.map((item) => {
            return {
              label: item.userName,
              value: item.id,
            };
          });
          this.initData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     *12.2 获取客户模板列表
     */
    initData() {
      let requestData = {
        companyName: this.searchData.companyName, // 筛选项：企业名称
        title: this.searchData.title, // 筛选项：有效期
        areaName: this.searchData.areaName, // 筛选项：区域
        validity: this.searchData.validity, // 筛选项有效期
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request("post", "/productPackage/page", requestData, (data) => {
        this.tableData = data.records;
        this.pageData.total = data.total;
      });
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    /**
     * 表格中按钮点击事件
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {};
      switch (active) {
        case "add":
          // 添加产品包
          this.dialogData.type = "add";
          this.dialogData.title = "添加产品包";
          this.dialogData.data = JSON.parse(JSON.stringify(this.orderList));
          this.dialogData.data[0].data[4].options = this.searchData.alreadyAreaSelectOption;
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "sure_add",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        case "give":
          // 赠送产品包
          this.dialogData.type = "add";
          this.dialogData.title = "赠送产品";
          this.dialogData.data = [
            {
              title: "产品包",
              data: [
                {
                  title: "赠送合作商",
                  type: "select",
                  value: "",
                  placeholder: "请输入",
                  tip: true, // 是否为必填项
                  landscape: true, // 是否横屏显示
                  key: "title",
                },
                {
                  title: "赠送数量",
                  type: "input",
                  value: "",
                  placeholder: "请输入",
                  tip: true, // 是否为必填项
                  landscape: true, // 是否横屏显示
                  key: "description",
                },
              ],
            },
          ];
          this.dialogData.data[0].data[0].options = this.userList;
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "sure_give",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        case "delete":
          // 删除产品包
          this.$confirm("确认要删除该产品包吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/productPackage/del?id=" + this.tempData.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.initData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "record":
          // 产品包赠送记录
          this.dialogData.type = "record";
          this.dialogData.title = "产品包赠送记录";
          this.dialogData.buttonList = [
            {
              name: "取消",
              active: "cancel",
            },
          ];
          requestData = {
            currentPage: this.dialogPageData.currentPage,
            pageSize: this.dialogPageData.pageSize,
            productPackageId: this.tempData.id, // 筛选项：产品包 id
          };
          this.$request(
            "post",
            "/productPackageGift/listByProductPackage",
            requestData,
            (data) => {
              this.dialogData.data = data.records;
              this.dialogPageData.total = data.total;
              this.dialogData.loading = false;
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    dialogHandleCilck(active) {
      let requestData = {};
      let flag = true;
      let numberReg = false;
      let keys = [];
      switch (active) {
        case "sure_add":
          // 确认添加产品包
          requestData = {
            createUserId: this.userData.userId, // 创建人id
            title: this.dialogData.data[0].data[0].value, // 标题
            description: this.dialogData.data[0].data[1].value, // 描述
            total: this.dialogData.data[0].data[2].value, // 总数量
            price: this.dialogData.data[0].data[3].value, // 价格
            areaId: this.dialogData.data[0].data[4].value, // 区域
            addressId: this.dialogData.data[0].data[5].value, // 地址规则
            validity: this.dialogData.data[0].data[6].value, // 有效期
            type: this.dialogData.data[0].data[7].value, // 类型
          };
          keys = Object.keys(requestData);
          flag = keys.every((item) => requestData[item]);
          if (!flag) {
            this.$message.error("请填写完整信息!");
            return;
          }
          numberReg = this.$numberReg(requestData.total);
          if (!numberReg) {
            this.$message.error("总数量必须为数字!");
            return;
          }
          numberReg = this.$numberReg(requestData.price);
          if (!numberReg) {
            this.$message.error("价格必须为数字!");
            return;
          }
          this.$request(
            "post",
            "/productPackage/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_give":
          // 确认赠送产品包
          requestData = {
            operator: this.userData.userId, // 当前登录用户
            agentId: this.dialogData.data[0].data[0].value, // 合作商 id
            productPackageId: this.tempData.id, // 产品包 id
            count: this.dialogData.data[0].data[1].value, // 赠送数量
          };
          keys = Object.keys(requestData);
          flag = keys.every((item) => requestData[item]);
          if (!flag) {
            this.$message.error("请填写完整信息!");
            return;
          }
          numberReg = this.$numberReg(requestData.count);
          if (!numberReg) {
            this.$message.error("赠送数量必须为数字!");
            return;
          }
          this.$request(
            "post",
            "/productPackageGift/add",
            requestData,
            (data) => {
              this.$message.success("赠送成功!");
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 切换区域
     */
    selectGroupChange() {
      let requestData = {
        areaId: this.dialogData.data[0].data[4].value, // 区域id
      };
      this.$request(
        "get",
        "/address/queryByAreaId?areaId=" + requestData.areaId,
        requestData,
        (data) => {
          this.dialogData.data[0].data[5].options = data.map((item) => {
            return {
              value: item.id, // id
              label: item.tag, // 地址前缀
              description: item.prefix, // 地址标签
            };
          });
          this.dialogData.data[0].data[5].value = "";
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 切换每页条数
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initData();
    },
    /**
     * 切换当前页数
     */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initData();
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "产品包",
      auth: "2",
      path: "/main/setting/syssetting-product",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#syssetting-product {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .file_text {
            color: @baseColor;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @activeColor;
              text-decoration: underline;
            }
          }
        }
        .edit {
          width: 100%;
        }
      }
    }
    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
    .record-content {
      padding: 20px;
      /deep/ .el-table {
        overflow-y: auto;
      }
    }
  }
}
</style>
