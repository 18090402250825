export function getTypeList() {
    return [{
        type: "0", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "单行文本",
        default: "",
        title: "",
        tip: "",
        value: "",
        placeholder: "", // 输入提示语
        not_null: true,
    }, {
        type: "1", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "多行文本",
        default: "",
        title: "",
        tip: "",
        value: "",
        placeholder: "", // 输入提示语
        not_null: true,
    }, {
        type: "2", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "日期选择",
        default: [],
        title: "",
        tip: "",
        itemType: "", // 时间/日期 类型  0:单个时间或日期  1:时间或日期范围
        value: "",
        not_null: true
    }, {
        type: "3", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "时间选择",
        default: [],
        title: "",
        tip: "",
        itemType: "", // 时间/日期 类型  0:单个时间或日期  1:时间或日期范围
        value: "",
        not_null: true
    }, {
        type: "4", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "单选",
        default: "",
        title: "",
        tip: "",
        options: [{ // 单选/多选 可供选择的值
            label: "",
            value: ""
        }],
        value: "",
        not_null: true
    }, {
        type: "5", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "多选",
        default: "",
        title: "",
        tip: "",
        options: [{ // 单选/多选 可供选择的值
            label: "",
            value: ""
        }],
        value: [],
        not_null: true
    }, {
        type: "6", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
        typeName: "图片",
        default: "",
        title: "",
        tip: "",
        itemType: "0", // 图片 类型  0:普通图片  1:身份证  2:营业执照
        value1: "",
        value2: "", // 若选择的是身份证,则该值为身份证反面绑定的值  value1为身份证正面绑定的值   其他情况下,绑定的值都为value1
        not_null: true
    }, ]
}

export function getTestTypeList() {
    return [{
            type: "0", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "单行文本",
            default: "",
            title: "",
            tip: "",
            value: "",
            placeholder: "", // 输入提示语
            not_null: true,
        }, {
            type: "1", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "多行文本",
            default: "",
            title: "",
            tip: "",
            value: "",
            placeholder: "", // 输入提示语
            not_null: true,
        }, {
            type: "2", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "日期选择",
            default: [],
            title: "",
            tip: "",
            itemType: "", // 时间/日期 类型  0:单个时间或日期  1:时间或日期范围
            value: "",
            not_null: true
        }, {
            type: "3", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "时间选择",
            default: [],
            title: "",
            tip: "",
            itemType: "", // 时间/日期 类型  0:单个时间或日期  1:时间或日期范围
            value: "",
            not_null: true
        }, {
            type: "4", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "单选",
            default: "",
            title: "",
            tip: "",
            options: [{ // 单选/多选 可供选择的值
                label: "",
                value: ""
            }],
            value: "",
            not_null: true
        }, {
            type: "5", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "多选",
            default: "",
            title: "",
            tip: "",
            options: [{ // 单选/多选 可供选择的值
                label: "",
                value: ""
            }],
            value: [],
            not_null: true
        }, {
            type: "6", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "图片",
            default: "",
            title: "",
            tip: "",
            itemType: "0", // 图片 类型  0:普通图片  1:身份证  2:营业执照
            value1: "",
            value2: "", // 若选择的是身份证,则该值为身份证反面绑定的值  value1为身份证正面绑定的值   其他情况下,绑定的值都为value1
            not_null: true
        },
        {
            type: "6", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "图片",
            default: "",
            title: "",
            tip: "",
            itemType: "1", // 图片 类型  0:普通图片  1:身份证  2:营业执照
            value1: "",
            value2: "", // 若选择的是身份证,则该值为身份证反面绑定的值  value1为身份证正面绑定的值   其他情况下,绑定的值都为value1
            not_null: true
        },
        {
            type: "6", // 0:单行文本  1:多行文本  2:日期  3:时间  4:单选  5:多选  6:图片
            typeName: "图片",
            default: "",
            title: "",
            tip: "",
            itemType: "2", // 图片 类型  0:普通图片  1:身份证  2:营业执照
            value1: "",
            value2: "", // 若选择的是身份证,则该值为身份证反面绑定的值  value1为身份证正面绑定的值   其他情况下,绑定的值都为value1
            not_null: true
        },
    ]
}

export function getOrderListByProduct() {
    return [
        {
          title: "产品包",
          data: [
            {
              title: "标题",
              type: "input",
              value: "",
              placeholder: "请输入",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "title",
            },
            {
              title: "描述",
              type: "input",
              value: "",
              placeholder: "请输入",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "description",
            },
            {
              title: "总数量",
              type: "input",
              value: "",
              placeholder: "请输入",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "total",
            },
            {
              title: "价格",
              type: "input",
              value: "",
              placeholder: "请输入",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "price",
            },
            {
              title: "区域",
              type: "select-group-change",
              value: "",
              options: [],
              placeholder: "请选择",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "areaId",
            },
            {
              title: "地址规则",
              type: "select",
              value: "",
              options: [],
              placeholder: "请选择",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "addressId",
            },
            {
              title: "有效期",
              type: "date-time",
              value: "",
              placeholder: "",
              tip: true, // 是否为必填项
              landscape: false, // 是否横屏显示
              key: "validity",
              format: "yyyy-MM-dd HH:mm:ss",
            },
            {
                title: "类型",
                type: "radio",
                value: "1",
                tip: true, // 是否为必填项
                landscape: false, // 是否横屏显示
                key: "type",
                options: [
                    { label: '个体户', value: '2' },
                    { label: '企业', value: '3' },
                ]
              },
          ],
        },
      ]
}