<template>
  <div
    id="syssetting-employee"
    class="div-flex-column-center"
    v-loading="loading"
  >
    <!-- 员工列表 -->
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span v-if="item.key == 'image'" v-viewer="{ movable: false }">
              <img :src="scope.row[item.key]" alt="" />
            </span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot="header">
            <el-button
              @click="handleClick({}, 'add')"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              >添加</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-for="(item, index) in tableOper"
              :key="index"
              v-show="item.show && item.statusShow"
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'edit' || dialogData.type == 'add'"
          style="width: 100%"
        >
          <div class="edit">
            <information_input :informationInputData="dialogData.data">
            </information_input>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as agentData from "../../assets/js/agentData.js";
export default {
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        input: "",
        roleList: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [
        {
          id: "4acb11f50ca04c288abf9b4e32690fc2",
          title: "安徽广电企服平台对联广告1",
          image: "../../assets/img/demo.jpg",
          time: "2022-11-30 14:57:16",
          status: "启用",
        },
        {
          id: "4acb11f50ca04c288abf9b4e32690fc2",
          title: "安徽广电企服平台对联广告2",
          image: "../../assets/img/demo.jpg",
          time: "2022-11-30 14:57:16",
          status: "禁用",
        },
      ],
      tableMenu: [
        {
          title: "姓名",
          key: "userName",
          width: "",
        },
        {
          title: "联系方式",
          key: "phone",
          width: "",
        },
        {
          title: "状态",
          key: "status",
          width: "",
        },
      ],
      tableOper: [
        {
          name: "编辑",
          key: "编辑",
          active: "edit",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: [],
      },
      orderList: [
        {
          title: "员工列表",
          data: [
            {
              title: "标题",
              type: "input",
              value: "",
              placeholder: "请输入标题",
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              key: "title",
            },
            {
              title: "状态",
              key: "status",
              type: "switch",
              value: false,
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              activeValue: "启用",
              inactiveValue: "禁用",
            },
            {
              title: "广告图片",
              key: "businessLicense",
              type: "file_reuse", // 仅文字展示
              tip: true, // 是否不得为空
              value: "",
              options: [],
              landscape: true, // 是否横屏显示
              placeholder: "选择附件", // 提示文字
              data: {
                systemName: "cluster_register",
                path: "",
                title: "广告图片",
              },
              isDisable: false,
              text: "", // 文件名称  显示在界面上的
            },
          ],
        },
      ],
      tempData: {},
      roleId: "",
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.roleId = data[0].roleId;
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "系统设置",
          "员工列表"
        );
        this.getAllRole();
        this.initData();
      });
    },
    getAllRole() {
      let requestData = {};
      this.$request("post", "/role/getAllRoleList", requestData, (data) => {
        this.searchData.roleList = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      });
    },
    /**
     *12.2 获取客户模板列表
     */
    initData() {
      let requestData = {
        userId: this.userData.userId, // 区域审核ID
        roleId: this.roleId, // 当前用户角色
        flag: "1", // 是否包含停用用户 0 不包含 1 包含
        search: {}, // 筛选条件
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/agent/getAllAgentByUserId",
        requestData,
        (data) => {
          this.tableData = data.list;
          this.pageData.total = data.total;
        }
      );
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    /**
     * 表格中按钮点击事件
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {};
      switch (active) {
        case "add":
          //   添加表单
          // 添加区域查询 或 总公司员工
          this.dialogData.type = "add";
          this.dialogData.data = agentData.getEmployeeDialogData();
          if (this.roleId == "1") {
            // 总公司
            console.log("");
            this.dialogData.title = "添加总公司员工";
            this.dialogData.data[0].data[2].options = [
              {
                label: "总公司员工",
                value: "6",
              },
            ];
            this.dialogData.data[0].data[2].value = "6";
          } else if (this.roleId == "2") {
            // 区域审核
            this.dialogData.title = "添加区域查询";
            this.dialogData.data[0].data[2].options = [
              {
                label: "区域查询",
                value: "3",
              },
            ];
            this.dialogData.data[0].data[2].value = "3";
          }
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_add",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          this.dialogData.loading = false;
          break;
        case "edit":
          // 编辑表单
          console.log("this.searchData.roleList", this.searchData.roleList);
          this.dialogData.title = "编辑区域查询";
          this.dialogData.type = "edit";
          this.dialogData.data = agentData.getEmployeeDialogData();
          this.dialogData.data[0].title = "编辑三级代理";
          this.dialogData.data[0].data[0].value = item.userName;
          this.dialogData.data[0].data[1].value = item.phone;
          this.dialogData.data[0].data[2].value = item.role;
          this.dialogData.data[0].data[2].options = this.searchData.roleList;
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_edit",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          this.dialogData.loading = false;
          break;
        case "delete":
          //   删除表单
          this.$confirm("此操作将删除该员工, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                userId: this.tempData.id,
              };
              this.$request(
                "post",
                "/agent/delAgentInfo",
                requestData,
                (data) => {
                  this.$message({
                    message: "删除成功!",
                    type: "success",
                  });
                  this.dialogData.flag = false;
                  this.initData();
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          this.dialogData.closeFlag = true;
          break;
        case "sure_add":
          // 确认添加区域查询
          requestData = {
            userId: this.userData.userId, // 当前区域审核ID
            userName: this.dialogData.data[0].data[0].value, // 总公司员工/区域查询姓名
            phone: this.dialogData.data[0].data[1].value, // 总公司员工/区域查询的手机号
            roleId: this.dialogData.data[0].data[2].value, // 角色ID
          };
          if (requestData.userName == "") {
            this.$message({
              message: "员工姓名不能为空!",
              type: "error",
            });
            return;
          }
          if (requestData.phone == "") {
            this.$message({
              message: "员工联系方式不能为空!",
              type: "error",
            });
            return;
          }
          this.$request(
            "post",
            "/agent/distributionAgent",
            requestData,
            (data) => {
              this.$message({
                message: "添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initData();
            }
          );
          break;
        case "sure_edit":
          // 确认编辑
          requestData = {
            userId: this.tempData.id, // 当前区域审核ID
            userName: this.dialogData.data[0].data[0].value, // 区域查询姓名
            phone: this.dialogData.data[0].data[1].value, // 区域查询的手机号
          };
          if (requestData.userName == "") {
            this.$message({
              message: "区域查询姓名不能为空!",
              type: "error",
            });
            return;
          }
          if (requestData.phone == "") {
            this.$message({
              message: "区域查询联系方式不能为空!",
              type: "error",
            });
            return;
          }
          this.$request(
            "post",
            "/agent/updateAgentInfo",
            requestData,
            (data) => {
              this.$message({
                message: "区域查询编辑成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "员工列表",
      auth: "2",
      path: "/main/setting/sheet",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#syssetting-employee {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .file_text {
            color: @baseColor;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @activeColor;
              text-decoration: underline;
            }
          }
        }
        .edit {
          width: 100%;
        }
      }
    }
    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
